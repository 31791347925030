.sessionTable {
    border-collapse: collapse;
    width: 100%;
    font-size: 100%;
}

table.sessionTable > tbody > tr > th{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

table.sessionTable > thead > tr > th:hover{
    text-shadow: 1px 1px 1px darkcyan;
    cursor: pointer;
}

.sessionTableRow:nth-child(even) {
    background-color: #f5f5f5;
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
    background-color:  white;
}

tr.sessionTableRow > td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.sessionListTitle{
    margin-left: 2%;
    margin-top: 1%;
}

.sessionListSubTitle{
    margin-left: 2.1%;
    margin-bottom: 0.5%;
    font-size: large;
}
.sessionListLink{
    font-weight: normal;
    text-decoration: none;
    color: black;
}