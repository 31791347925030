input[type="range"]::-webkit-slider-thumb {
    background: #027594 !important;
    border: 0;
}

input[type="range"]:focus::-webkit-slider-thumb {
    background: #027594 !important;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px !important;
    border: 0;
}

.textAreaSessionEdit{
    /*border:solid 2px #0000008C;
    background-color:#00000033;*/
    padding:0.5%;
    border-radius:5px;
    height:7em;
}

.textAreaSessionEdit:focus{
    border-color: #027594;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px;
    /*background-color: #00000033;*/
}

.inputFieldSessionEdit:focus{
    border-color: #027594;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px;
}

.form-check-input:checked{
    background-color: #027594;
    border-color: #027594;
}

.form-check-input:focus{
    border-color: #027594;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px;
}
