.textAreaReview{
    border:solid 2px #0000008C;
    background-color:#00000033;
    padding:0.5%;
    border-radius:5px;
    height:7em;
}

.textAreaReview:active{
    background-color: #00000033;
}

.textAreaReview:focus{
    border-color: #027594;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px;
    background-color: #00000033;
}

.inputFieldNumberReview{
    width:4em;
    display:inline;
    border:solid 2px #0000008C;
    background-color:#00000033;
    padding:0.5%;
    border-radius:5px;
}

.inputFieldNumberReview:focus{
    border-color: #027594;
    box-shadow: rgba(2, 117, 148, 0.52) 0 0 4px 4px;
    background-color: #00000033;
}

.error1010{
    color: #027594;
    font-weight: bold;
}