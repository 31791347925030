.program-table {
    display: grid;
    grid-template-columns: fit-content(100%);
    grid-auto-flow: row;
    grid-gap: 1em 1.5em;
}

.program-table .program-cell {
    padding: .5em;
}

.program-table .program-cell.text {
    background-color: #d0caca;
}

.program-location-cell, .program-time-cell {
    display: grid;
    align-items: center;
    text-align: center;
}

.program-location-cell, .program-time-cell {
    color: #333;
}

.program-location-cell {
    padding: 1em 0 0 0;
}

.program-time-cell {
    padding: 0 0 0 1.5em;
}

.program-session-cell {
    display: grid;
    place-items: center;
    padding: 1em 1.5em;
}

.program-session-info, .program-session-presenter {
    font-size: 0.8125em;
    text-align: center;
    text-underline-offset: 3px;
}

.program-text {
    color: #333;
}

.program-session-presenters {
    text-align: center;
}

.program-text {
    padding: 2em 0;
    text-align: center;
}