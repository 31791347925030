.navMenu {
    border-bottom: 3px solid #027594;
}

.navItem {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
}

.nav-link:hover {
    color: #027594;
}