#footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
    gap: 1em;
}

#footer-logo {
    order: -1;
    filter: grayscale(100%);
}

#footer-socialss {
    display: flex;
    gap: 1em;
}

#footer-socialss a, #footer-socialss a img {
    max-width: 24px;
    max-height: 24px;
}

#footer-socialss a img {
    vertical-align: baseline;
}

@media screen and (min-width: 768px) {
    #footer {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto 1fr auto;
        gap: 0;
    }

    #footer > * {
        grid-row: 1;
    }

    #footer #footer-text {
        grid-column: 1 / 2;
    }

    #footer #footer-logo {
        grid-column: 1 / 4;
        place-self: center;
    }

    #footer #footer-socialss {
        grid-column: 3 / 4;
    }
}